<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="3">
							<pui-select
								:id="`portid-${modelName}`"
								:attach="`portid-${modelName}`"
								:label="$t('form.pilot.portname')"
								toplabel
								clearable
								:disabled="formDisabled"
								v-model="model"
								modelName="port"
								:modelFormMapping="{ id: 'portid' }"
								:itemsToSelect="itemsToSelectPort"
								itemValue="id"
								itemText="portname"
								required
								:fixedFilter="filterByPortAuthorityAndPort"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`nif-pilot`"
								v-model="model.nif"
								:label="$t('form.pilot.nif')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<v-col cols="5">
							<pui-text-field
								:id="`fullname-pilot`"
								v-model="model.fullname"
								:label="$t('form.pilot.fullname')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'pilot-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'pilot'
		};
	},
	methods: {},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.model.portid }];
		},
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		}
	},
	watch: {
		'model.portid': {
			handler: function (newValue) {
				if (typeof newValue === 'undefined' || newValue == null) {
					this.model.portauthority = null;
				} else {
					this.model.portauthority = this.$store.getters.getPortAuthorityId;
				}
			}
		}
	},
	created() {}
};
</script>
